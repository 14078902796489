import React, { Component } from 'react';
import './App.scss';

import { CustomerPage, ProjectBuilderPage, ProjectListPage } from './pages'


import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { HeaderComp } from './components'
import Favicon from 'react-favicon';


if (process.env.NODE_ENV === 'production')
  console.log = function () { }

class App extends Component {

  componentDidMount() {
    
  }


  render() {
    // console.log("Host URL"+process.env.PUBLIC_URL);
    // let {sideMenuOpen} = this.props
    return (
      <Router basename={process.env.PUBLIC_URL}>
        <div className="App">
          <Favicon url='/static/dsb_assets/DSB_LOGO.png' />
          <Switch>
            <Route exact path="/" component={props => <HeaderComp {...props} />} />
          </Switch>
          <Switch>
            <Route exact path="/" component={props => <ProjectBuilderPage {...props} />} />
            <Route path="/client" component={props => <CustomerPage {...props} />} />
            <Route path="/project" component={props => <CustomerPage {...props} />} />
            <Route path="/project-list/:customer" component={props => <ProjectListPage {...props} />} />
          </Switch>
        </div>
      </Router>
    );
  }
}


export default App;
